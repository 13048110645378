<template>
  <div class="cms-media-lorem-ipsum">
    <p
      v-for="(p,i) in parragraphs"
      :key="i"
    >{{ p }}</p>

    <div class="ui-row --tight">
      <button
        v-for="listener in allListeners"
        :key="listener"
        type="button"
        class="ui-button"
        @click="$emit(listener, {evt:listener, foo: generateParagraph(validateInt(Math.random()*10)) })"
      >{{ listener }}</button>
    </div>
  </div>
</template>

<script>
export default {
  name: 'cms-media-lorem-ipsum',

  props: {
    text: {
      type: String,
      default:
        'Lorem ipsum dolor sit amet, consectetur adipiscing elit. Curabitur egestas malesuada habichuela porta. Nam dapibus magna ac lacus viverra, condimentum feugiat velit consectetur. Interdum et malesuada fames ac ante ipsum primis in faucibus. Etiam dignissim tempus nulla, in lacinia orci aliquam sed. Praesent eu lobortis erat. Sed eros velit, laoreet in hendrerit id, commodo vel velit. Nullam at lorem at mi luctus pulvinar in quis orci. Curabitur eget sollicitudin dolor. Fusce ultricies est nunc, vitae vehicula metus dapibus eu. Donec in velit ac nibh dictum mattis. Duis mollis lectus sed varius dapibus. Suspendisse potenti. Nunc fringilla, dui id vestibulum malesuada, metus massa ultricies metus, sed lacinia ligula tellus sit amet ante. Morbi feugiat in ex vitae scelerisque. Donec accumsan ultrices ex, in faucibus dolor aliquet et.'
    },

    nParagraphs: {
      required: false
    },

    nWords: {
      required: false
    }
  },

  computed: {
    parragraphs() {
      let retval = [];
      for (let i = 0; i < this.nParagraphsInt; i++) {
        retval.push(this.generateParagraph(this.nWordsInt));
      }

      return retval;
    },

    nParagraphsInt() {
      return this.validateInt(this.nParagraphs, 1, 50, 2);
    },

    nWordsInt() {
      return this.validateInt(this.nWords, 10, 400, 120);
    },

    allListeners() {
      return Object.keys(this.$listeners).filter(e => e != 'input');
    }
  },

  methods: {
    validateInt(value, min, max, defaultValue) {
      let retval = parseInt(value);
      if (Number.isNaN(retval)) {
        return defaultValue;
      }

      if (retval < min) {
        return min;
      }

      if (retval > max) {
        return max;
      }

      return retval;
    },

    generateParagraph(n) {
      if (!n) {
        return this.text;
      }

      let retval = [];
      let words = this.text.split(' ');

      let i = 0;
      while (retval.length < n) {
        // wrap around text
        let index = i % words.length;

        retval.push(words[index]);
        i++;
      }

      return retval.join(' ');
    }
  }
};
</script>